<template>
  <div>
    <el-radio-group
      v-model="selectValue"
      @change="$emit('input', selectValue)"
    >
      <el-radio
        v-for="item in options"
        :key="item.key"
        :label="item.key"
      >{{ item.label }}
      </el-radio>
    </el-radio-group>
  </div>
</template>

<script>
export default {
  name: 'RadioField',
  props: {
    value: { },
    options: {
      type: Array,
      default: () => []
    },
    size: {
      type: String,
      default: 'mini'
    }
  },
  data: function () {
    return {
      selectValue: this.value
    }
  }
}
</script>

<style scoped>
.el-radio {
    font-weight: normal;
}
</style>
