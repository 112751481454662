<template>
  <div>
    <el-dialog
      :visible.sync="showWindow"
      class="dialog"
      top="7vh"
      title="Экспорт контуров"
      :modal="true"
      :modal-append-to-body="true"
      :append-to-body="true"
      width="700px"
    >
      <div class="content">
        <div class="settings-label">
          Основные свойства
        </div>
        <el-row>
          <el-col :span="16">Формат</el-col>
          <el-col :span="8">
            <el-select
              v-model="defaultSettings.format"
              size="mini"
            >
              <el-option
                v-for="item in formats"
                :key="item.tag"
                :value="item.tag"
                :label="item.description"
              ></el-option>
            </el-select>
          </el-col>
        </el-row>
        <el-row v-if="type === exportTypes.FEATURES">
          <el-col :span="16">Экспортировать слой целиком</el-col>
          <el-col :span="8">
            <el-checkbox v-model="defaultSettings.exportWholeLayers"></el-checkbox>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="16">Наименование</el-col>
          <el-col :span="8">
            <el-input size="mini" v-model="defaultSettings.name"></el-input>
            <p v-if="!valid.name" class="error">заполните поле</p>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="16">Система координат</el-col>
          <el-col :span="8">
            <coordinate-system
              class="coordinateSystem"
              size="mini"
              :options="userCS"
              @change-cs="(csId) => defaultSettings.cs = csId"
            ></coordinate-system>
            <p v-if="!valid.cs" class="error">заполните поле</p>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="16">Поворот перед преобразованием</el-col>
          <el-col :span="8">
            <el-checkbox v-model="defaultSettings.axisRotationBeforeTransform"></el-checkbox>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="16">Поворот после преобразования</el-col>
          <el-col :span="8">
            <el-checkbox v-model="defaultSettings.axisRotationAfterTransform"></el-checkbox>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="16">Преобразовать замкнутые линии в полигоны</el-col>
          <el-col :span="8">
            <el-checkbox v-model="defaultSettings.closedLineStringToPolygon"></el-checkbox>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="16">Удалять z-координату</el-col>
          <el-col :span="8">
            <el-checkbox v-model="defaultSettings.flattening"></el-checkbox>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="16">Обработка ошибок</el-col>
          <el-col :span="8">
            <el-select
              v-model="defaultSettings.errorHandling"
              size="mini">
              <el-option
                v-for="item in errorBehaviour"
                :key="item.id"
                :value="item.id"
                :label="item.name"
              ></el-option>
            </el-select>
          </el-col>
        </el-row>
        <div
          v-if="additionalSettings.length > 0"
          class="additional-settings-container">
          <div class="settings-label">
            Дополнительные свойства
          </div>
          <el-row
            v-for="item in additionalSettings" :key="item.name" :style="{height: fieldTypes[item.type] == 'RadioField' ? item.options.length * 25 + 'px' : 'initial'}"
          >
            <el-col :span="16">{{item.description}}</el-col>
            <el-col :span="8">
              <component
                :is="fieldTypes[item.type]"
                :value="item.value"
                :options="item.options"
                size="mini"
                @input="(val) => item.value = val"
              ></component>
            </el-col>
          </el-row>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="close">Отмена</el-button>
        <el-button type="primary" @click="send" :loading="isLoading">Экспортировать</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>

import { APIClient } from '@/core/infrastructure/api/APIClient'
import { DotNetSpatialServiceAPI } from '@/core/infrastructure/api/modules/DotNetSpatialServiceAPI'
import CoordinateSystem from '@/core/infrastructure/components/CoordinateSystem'

import SelectField from './SelectField'
import StringField from './StringField'
import RadioField from './RadioField'

export const EXPORT_TYPES = {
  LAYER: 'layer',
  FEATURES: 'features'
}
const ERROR_BEHAVIOUR = [
  { id: 'stop', name: 'Останавливать выгрузку' },
  { id: 'exportBroken', name: 'Выгружать "кривые" контура' },
  { id: 'skipBroken', name: 'Пропускать "кривые" контура' }
]

const FIELD_TYPES = {
  DropDown: 'SelectField',
  String: 'StringField',
  Radio: 'RadioField'
}

export default {
  name: 'ExportFeaturesWindow',
  components: {
    CoordinateSystem,
    SelectField,
    StringField,
    RadioField
  },
  inject: {
    changeElDialogFocus: {
      default: () => {}
    }
  },
  props: {
    userCS: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      showWindow: false,
      isLoading: false,
      type: null,
      layer: null,
      features: [],
      defaultSettings: {
        format: 'MIF',
        cs: null,
        name: 'export',
        axisRotationBeforeTransform: false,
        axisRotationAfterTransform: false,
        closedLineStringToPolygon: false,
        flattening: false,
        exportWholeLayers: false,
        errorHandling: ERROR_BEHAVIOUR[0].id
      },
      additionalSettings: [],
      formats: [],
      sent: false,
      valid: {
        name: true,
        cs: true
      }
    }
  },
  watch: {
    selectedFormat: {
      handler: function (newValue, oldValue) {
        this.additionalSettings.splice(0, this.additionalSettings.length)
        newValue.extended_parameters.forEach((item) => {
          this.additionalSettings.splice(
            this.additionalSettings.length, 0, {
              ...item,
              value: item.default || null,
              options: Object.entries(item.values).map(x => { return {key: x[0], label: x[1]}})
            }
          )
        })
      }
    },
    showWindow: {
      handler: function (newValue) {
        this.changeElDialogFocus(newValue)
      }
    }
  },
  computed: {
    errorBehaviour: () => ERROR_BEHAVIOUR,
    fieldTypes: () => FIELD_TYPES,
    exportTypes: () => EXPORT_TYPES,
    selectedFormat: function () {
      return this.formats.find(x => x.tag === this.defaultSettings.format)
    }
  },
  async mounted () {
  },
  methods: {
    async loadMetaData () {
      this.formats = await APIClient.shared.request(
        new DotNetSpatialServiceAPI.LoadExportMetaData()
      )
    },
    validate () {
      this.valid.name = this.defaultSettings.name != null && this.defaultSettings.name.trim() !== ''
      this.valid.cs = this.defaultSettings.cs != null
      return this.valid.name && this.valid.cs
    },
    async send () {
      if (!this.validate()) {
        return
      }
      let payload = []
      switch (this.type) {
        case EXPORT_TYPES.LAYER:
          if (!this.layer.layerData.source.entityId) {
            this.showError('Экспорт из слоев, отличных от типа "Реестр", невозможен.')
            return
          }
          payload.push({
            entityId: this.layer.layerData.source.entityId,
            filters: this.layer.lastFilters,
            geomFieldId: this.layer.layerData.source.geometryField,
            fields: this.layer.layerData.interactive.standardCard.fields.map(item => 'attr_' + item.id.toString() + '_'),
            exportType: 'layer'
          })
          break
        case EXPORT_TYPES.FEATURES:
          let featureIdsByLayerGuid = []
          let layersForExport = []
          let proceedLayers = []
          this.features.forEach((feature) => {
            // экспортируем слои целиком
            if (!proceedLayers.includes(feature.layerGuid)) {
              layersForExport.push({
                ...feature.layerProperties,
                filters: feature.layerFilters,
                guid: feature.layerGuid
              })
              proceedLayers.push(feature.layerGuid)
            }

            // если экспортируем не слои целиком, то формируем массив с id фич для экспорта
            if (!this.defaultSettings.exportWholeLayers) {
              if (!featureIdsByLayerGuid[feature.layerGuid]) {
                featureIdsByLayerGuid[feature.layerGuid] = []
              }
              featureIdsByLayerGuid[feature.layerGuid].push(feature.properties.guid)
            }
          })
          let entityIdExists = true
          layersForExport.forEach((layerForExport) => {
            if (!layerForExport.source.entityId) {
              entityIdExists = false
            }
            let layer = {
              entityId: layerForExport.source.entityId,
              filters: layerForExport.lastFilters,
              geomFieldId: layerForExport.source.geometryField,
              fields: layerForExport.interactive.standardCard.fields.map(item => 'attr_' + item.id.toString() + '_'),
              exportType: 'feature'
            }
            if (!this.defaultSettings.exportWholeLayers) {
              if (featureIdsByLayerGuid[layerForExport.guid] &&
                  featureIdsByLayerGuid[layerForExport.guid].length > 0) {
                /* layer.filters = {
                  'and': [
                    {
                      'in': { 'guid': featureIdsByLayerGuid[layerForExport.guid] }
                    }
                  ]
                } */
                layer.filters = {
                  'and': [
                    {
                      'equals_any': { 'guid': featureIdsByLayerGuid[layerForExport.guid] }
                    }
                  ]
                }
              }
            }
            payload.push(layer)
          })
          if (!entityIdExists) {
            this.showError('Экспорт из слоев, отличных от типа "Реестр", невозможен.')
            return
          }
          // если экспортируем не слои целиком, то нужно добавить айдишники фич в фильтры
          break
        default:
          return
      }
      let result = { ...this.defaultSettings, layers: payload }
      this.additionalSettings.forEach((item) => {
        result[item.name] = item.value
      })

      try {
        const answer = await APIClient.shared.request(
          new DotNetSpatialServiceAPI.Export(result, this.defaultSettings.name + '.zip')
        )
      } catch (e) {
        const jsonResponse = JSON.parse(await e.raw.response.data.text())
        this.$alert(JSON.stringify(jsonResponse), 'Ошибка', { confirmButtonText: 'OK' })
      }
    },
    getLayerSettings (layer) {
      return {
        url: `${this.$config.api}/mapeditor/geojson/registry/${layer.source.entityId}`,
        filters: layer.lastFilters
      }
    },
    async open (type, source) {
      switch (type) {
        case EXPORT_TYPES.LAYER:
          this.defaultSettings.exportWholeLayers = true
          this.layer = source
          break
        case EXPORT_TYPES.FEATURES:
          this.features = source
          break
        default:
          console.log('unknown type', type)
          return
      }
      await this.loadMetaData()
      this.type = type
      this.showWindow = true
    },
    close () {
      this.layer = null
      this.features = []
      this.showWindow = false
    },
    showError (text) {
      this.$message({
        type: 'error',
        dangerouslyUseHTMLString: true,
        message: text
      })
    }
  }
}
</script>

<style scoped>
  .settings-label {
    height: 25px;
    font-size: 16px;
    font-weight: bold;
  }
  .el-row {
    margin: 5px;
    border-radius: 4px;
  }
  .el-row:nth-of-type(2n) {
    background: #e7e7e7;
  }
  .el-col {
    padding: 5px;
  }
  .additional-settings-container {
    margin-top: 24px;
  }
  .error {
    color: red;
    font-size: 11px;
    margin-top: 0;
  }
 </style>
